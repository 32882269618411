define("ember-form-for/templates/components/form-fields/hidden-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BpJeKqaz",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"form-field\",[[23,[\"propertyName\"]]],[[\"class\",\"classNames\",\"errorClasses\",\"hintClasses\",\"inputClasses\",\"labelClasses\",\"serializeValue\",\"deserializeValue\",\"object\",\"hint\",\"form\",\"label\",\"control\",\"update\"],[\"hidden-field\",[23,[\"fieldClasses\"]],[23,[\"errorClasses\"]],[23,[\"hintClasses\"]],[23,[\"inputClasses\"]],[23,[\"labelClasses\"]],[23,[\"serializeValue\"]],[23,[\"deserializeValue\"]],[23,[\"object\"]],[23,[\"hint\"]],[23,[\"form\"]],[23,[\"label\"]],[23,[\"control\"]],[27,\"action\",[[22,0,[]],[23,[\"update\"]]],null]]],{\"statements\":[[0,\"  \"],[1,[27,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"control\"]],\"expected `f.control` to be a contextual component but found a string. Did you mean `(component f.control)`? ('ember-form-for/templates/components/form-fields/hidden-field.hbs' @ L16:C4) \"],null]],[[\"accept\",\"accesskey\",\"autocomplete\",\"autofocus\",\"autosave\",\"dir\",\"disabled\",\"hidden\",\"inputmode\",\"lang\",\"list\",\"max\",\"maxlength\",\"min\",\"minlength\",\"multiple\",\"pattern\",\"placeholder\",\"readonly\",\"required\",\"size\",\"spellcheck\",\"step\",\"tabindex\",\"title\"],[[23,[\"accept\"]],[23,[\"accesskey\"]],[23,[\"autocomplete\"]],[23,[\"autofocus\"]],[23,[\"autosave\"]],[23,[\"dir\"]],[23,[\"disabled\"]],[23,[\"hidden\"]],[23,[\"inputmode\"]],[23,[\"lang\"]],[23,[\"list\"]],[23,[\"max\"]],[23,[\"maxlength\"]],[23,[\"min\"]],[23,[\"minlength\"]],[23,[\"multiple\"]],[23,[\"pattern\"]],[23,[\"placeholder\"]],[23,[\"readonly\"]],[23,[\"required\"]],[23,[\"size\"]],[23,[\"spellcheck\"]],[23,[\"step\"]],[23,[\"tabindex\"]],[23,[\"title\"]]]]],false],[0,\"\\n  \"],[1,[22,1,[\"errors\"]],false],[0,\"\\n  \"],[1,[22,1,[\"hint\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-form-for/templates/components/form-fields/hidden-field.hbs"
    }
  });

  _exports.default = _default;
});