define("ember-form-for/templates/components/form-fields/checkbox-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hwxw4SRZ",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"form-field\",[[23,[\"propertyName\"]]],[[\"class\",\"classNames\",\"errorClasses\",\"hintClasses\",\"inputClasses\",\"labelClasses\",\"object\",\"form\",\"hint\",\"label\",\"control\",\"update\"],[\"checkbox-field\",[23,[\"fieldClasses\"]],[23,[\"errorClasses\"]],[23,[\"hintClasses\"]],[23,[\"inputClasses\"]],[23,[\"labelClasses\"]],[23,[\"object\"]],[23,[\"form\"]],[23,[\"hint\"]],[23,[\"label\"]],[23,[\"control\"]],[27,\"action\",[[22,0,[]],[23,[\"update\"]]],null]]],{\"statements\":[[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"label\"]],\"expected `f.label` to be a contextual component but found a string. Did you mean `(component f.label)`? ('ember-form-for/templates/components/form-fields/checkbox-field.hbs' @ L14:C5) \"],null]],[[\"required\"],[[23,[\"required\"]]]],{\"statements\":[[0,\"    \"],[1,[27,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"control\"]],\"expected `f.control` to be a contextual component but found a string. Did you mean `(component f.control)`? ('ember-form-for/templates/components/form-fields/checkbox-field.hbs' @ L15:C6) \"],null]],[[\"accesskey\",\"autocomplete\",\"autofocus\",\"autosave\",\"dir\",\"disabled\",\"hidden\",\"lang\",\"list\",\"required\",\"tabindex\",\"title\"],[[23,[\"accesskey\"]],[23,[\"autocomplete\"]],[23,[\"autofocus\"]],[23,[\"autosave\"]],[23,[\"dir\"]],[23,[\"disabled\"]],[23,[\"hidden\"]],[23,[\"lang\"]],[23,[\"list\"]],[23,[\"required\"]],[23,[\"tabindex\"]],[23,[\"title\"]]]]],false],[0,\"\\n    \"],[1,[22,1,[\"labelText\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[22,1,[\"errors\"]],false],[0,\"\\n  \"],[1,[22,1,[\"hint\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-form-for/templates/components/form-fields/checkbox-field.hbs"
    }
  });

  _exports.default = _default;
});