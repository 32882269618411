define("ember-form-for/templates/components/form-controls/submit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zBC0AbSv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"  \"],[14,1,[[22,0,[]],[23,[\"activePromise\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"activePromise\",\"isPending\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"if\",[[23,[\"pending\"]],[23,[\"pending\"]],[23,[\"default\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"activePromise\",\"isFulfilled\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"if\",[[23,[\"fulfilled\"]],[23,[\"fulfilled\"]],[23,[\"default\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"activePromise\",\"isRejected\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"if\",[[23,[\"rejected\"]],[23,[\"rejected\"]],[23,[\"default\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[21,\"default\"],false],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-form-for/templates/components/form-controls/submit.hbs"
    }
  });

  _exports.default = _default;
});