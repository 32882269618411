define("ember-elsewhere/templates/components/multiple-from-elsewhere", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j8RH3r/C",
    "block": "{\"symbols\":[\"source\",\"&default\"],\"statements\":[[4,\"if\",[[23,[\"initialized\"]]],null,{\"statements\":[[4,\"each\",[[27,\"get\",[[23,[\"service\",\"actives\"]],[23,[\"name\"]]],null]],null,{\"statements\":[[4,\"if\",[[24,2]],null,{\"statements\":[[0,\"      \"],[14,2,[[22,1,[\"component\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[27,\"component\",[[22,1,[\"component\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-elsewhere/templates/components/multiple-from-elsewhere.hbs"
    }
  });

  _exports.default = _default;
});