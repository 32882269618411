define("ember-sortable/helpers/drag", ["exports", "@ember/test-helpers"], function (_exports, _testHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.drag = drag;
  _exports.default = void 0;

  /**
    Drags elements by an offset specified in pixels.
  
    Examples
  
        drag(
          'mouse',
          '.some-list li[data-item=uno]',
          function() {
            return { dy: 50, dx: 20 };
          }
        );
  
    @method drag
    @param {'mouse'|'touch'} [mode]
      event mode
    @param {String} [itemSelector]
      selector for the element to drag
    @param {Function} [offsetFn]
      function returning the offset by which to drag
    @param {Object} [callbacks]
      callbacks that are fired at the different stages of the interaction
    @return {Promise}
  */
  function drag(app, mode, itemSelector, offsetFn) {
    var callbacks = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
    var start, move, end, which;
    var _app$testHelpers = app.testHelpers,
        andThen = _app$testHelpers.andThen,
        findWithAssert = _app$testHelpers.findWithAssert,
        wait = _app$testHelpers.wait;

    if (mode === 'mouse') {
      start = 'mousedown';
      move = 'mousemove';
      end = 'mouseup';
      which = 1;
    } else if (mode === 'touch') {
      start = 'touchstart';
      move = 'touchmove';
      end = 'touchend';
    } else {
      throw new Error("Unsupported mode: '".concat(mode, "'"));
    }

    andThen(function () {
      var item = findWithAssert(itemSelector);
      var itemOffset = item.offset();
      var offset = offsetFn();
      var itemElement = item.get(0);
      var rect = itemElement.getBoundingClientRect(); // firefox gives some elements, like <svg>, a clientHeight of 0.
      // we can try to grab it off the parent instead to have a better
      // guess at what the scale is.
      // https://bugzilla.mozilla.org/show_bug.cgi?id=874811#c14
      // https://stackoverflow.com/a/13647345
      // https://stackoverflow.com/a/5042051

      var dx = offset.dx || 0;
      var dy = offset.dy || 0;
      var clientHeight = itemElement.clientHeight || item.offsetHeight || itemElement.parentNode.offsetHeight;
      var scale = clientHeight / (rect.bottom - rect.top);
      var halfwayX = itemOffset.left + dx * scale / 2;
      var halfwayY = itemOffset.top + dy * scale / 2;
      var targetX = itemOffset.left + dx * scale;
      var targetY = itemOffset.top + dy * scale;
      andThen(function () {
        (0, _testHelpers.triggerEvent)(itemElement, start, {
          clientX: itemOffset.left,
          clientY: itemOffset.top,
          which: which
        });
      });

      if (callbacks.dragstart) {
        andThen(callbacks.dragstart);
      }

      andThen(function () {
        (0, _testHelpers.triggerEvent)(itemElement, move, {
          clientX: itemOffset.left,
          clientY: itemOffset.top
        });
      });

      if (callbacks.dragmove) {
        andThen(callbacks.dragmove);
      }

      andThen(function () {
        (0, _testHelpers.triggerEvent)(itemElement, move, {
          clientX: halfwayX,
          clientY: halfwayY
        });
      });
      andThen(function () {
        (0, _testHelpers.triggerEvent)(itemElement, move, {
          clientX: targetX,
          clientY: targetY
        });
      });
      andThen(function () {
        (0, _testHelpers.triggerEvent)(itemElement, end, {
          clientX: targetX,
          clientY: targetY
        });
      });

      if (callbacks.dragend) {
        andThen(callbacks.dragend);
      }
    });
    return wait();
  }

  var _default = Ember.Test.registerAsyncHelper('drag', drag);

  _exports.default = _default;
});