define("ember-form-for/templates/components/form-fields/checkbox-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n1tl+uqc",
    "block": "{\"symbols\":[\"f\",\"option\"],\"statements\":[[4,\"form-field\",[[23,[\"propertyName\"]]],[[\"tagName\",\"class\",\"classNames\",\"disabled\",\"errorClasses\",\"hintClasses\",\"inputClasses\",\"labelClasses\",\"object\",\"hint\",\"form\",\"label\"],[\"fieldset\",\"checkbox-group\",[23,[\"fieldClasses\"]],[23,[\"disabled\"]],[23,[\"errorClasses\"]],[23,[\"hintClasses\"]],[23,[\"inputClasses\"]],[23,[\"labelClasses\"]],[23,[\"object\"]],[23,[\"hint\"]],[23,[\"form\"]],[23,[\"label\"]]]],{\"statements\":[[0,\"  \"],[1,[27,\"form-label\",[[22,1,[\"labelText\"]]],[[\"required\",\"tagName\"],[[23,[\"required\"]],\"legend\"]]],false],[0,\"\\n\\n  \"],[7,\"ul\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"options\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"form-fields/checkbox-group/option\",null,[[\"object\",\"propertyName\",\"option\",\"update\",\"disabled\",\"dir\",\"lang\",\"value\"],[[23,[\"object\"]],[23,[\"propertyName\"]],[22,2,[]],[27,\"action\",[[22,0,[]],\"updateSelection\",[22,2,[]]],null],[23,[\"disabled\"]],[23,[\"dir\"]],[23,[\"lang\"]],[22,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[10],[0,\"\\n\\n  \"],[1,[22,1,[\"errors\"]],false],[0,\"\\n  \"],[1,[22,1,[\"hint\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-form-for/templates/components/form-fields/checkbox-group.hbs"
    }
  });

  _exports.default = _default;
});