define("ember-form-for/templates/components/form-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mlGDfA5j",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"object\",\"propertyName\",\"labelText\",\"label\",\"errors\",\"hasErrors\",\"hint\",\"control\"],[[23,[\"object\"]],[23,[\"propertyName\"]],[23,[\"labelText\"]],[27,\"component\",[\"form-label\",[23,[\"labelText\"]]],[[\"for\",\"classNames\",\"required\",\"form\"],[[23,[\"fieldId\"]],[23,[\"_labelClasses\"]],[23,[\"required\"]],[23,[\"form\"]]]]],[27,\"component\",[\"form-errors\"],[[\"errorId\",\"errors\",\"errorClasses\"],[[27,\"concat\",[[23,[\"fieldId\"]],\"_error\"],null],[23,[\"errors\"]],[23,[\"_errorClasses\"]]]]],[23,[\"hasErrors\"]],[27,\"component\",[\"form-hint\"],[[\"hint\",\"hintClasses\",\"hintId\"],[[23,[\"hint\"]],[23,[\"_hintClasses\"]],[27,\"concat\",[[23,[\"fieldId\"]],\"_hint\"],null]]]],[27,\"component\",[[23,[\"control\"]],[23,[\"value\"]]],[[\"id\",\"name\",\"classNames\",\"required\",\"invalid\",\"aria-invalid\",\"aria-describedby\",\"form\",\"update\"],[[23,[\"fieldId\"]],[23,[\"fieldName\"]],[23,[\"_inputClasses\"]],[23,[\"required\"]],[27,\"if\",[[23,[\"errors\"]],true,false],null],[27,\"if\",[[23,[\"errors\"]],\"true\",\"false\"],null],[23,[\"describedByValue\"]],[23,[\"form\"]],[27,\"action\",[[22,0,[]],\"processUpdate\",[23,[\"object\"]],[23,[\"propertyName\"]]],null]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-form-for/templates/components/form-field.hbs"
    }
  });

  _exports.default = _default;
});