define("ember-form-for/templates/components/form-fields/radio-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c972do8R",
    "block": "{\"symbols\":[\"f\",\"option\"],\"statements\":[[4,\"form-field\",[[23,[\"propertyName\"]]],[[\"tagName\",\"class\",\"classNames\",\"errorClasses\",\"hintClasses\",\"inputClasses\",\"labelClasses\",\"object\",\"hint\",\"form\",\"label\"],[\"fieldset\",\"radio-group\",[23,[\"fieldClasses\"]],[23,[\"errorClasses\"]],[23,[\"hintClasses\"]],[23,[\"inputClasses\"]],[23,[\"labelClasses\"]],[23,[\"object\"]],[23,[\"hint\"]],[23,[\"form\"]],[23,[\"label\"]]]],{\"statements\":[[0,\"  \"],[1,[27,\"form-label\",[[22,1,[\"labelText\"]]],[[\"required\",\"tagName\"],[[23,[\"required\"]],\"legend\"]]],false],[0,\"\\n\\n  \"],[7,\"ul\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"options\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"form-fields/radio-field\",[[23,[\"propertyName\"]],[22,2,[]]],[[\"disabled\",\"tagName\",\"object\",\"update\"],[[23,[\"disabled\"]],\"li\",[23,[\"object\"]],[27,\"action\",[[22,0,[]],[23,[\"update\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[10],[0,\"\\n\\n  \"],[1,[22,1,[\"errors\"]],false],[0,\"\\n  \"],[1,[22,1,[\"hint\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-form-for/templates/components/form-fields/radio-group.hbs"
    }
  });

  _exports.default = _default;
});